import React , { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'

const LoginPage = () => {

  const userRef = useRef()
  const errRef = useRef()
  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const navigate = useNavigate()

  // const [login, { isLoading }] = useLoginMutation() // isLoading is created automatically
  const [login] = useLoginMutation()
  const dispatch = useDispatch()

  useEffect(() => {
      userRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('')
}, [user, pwd])

const handleSubmit = async (e) => {
  e.preventDefault()

  try {
      const userData = await login({ user, pwd }).unwrap()
      dispatch(setCredentials({ ...userData, user }))
      setUser('')
      setPwd('')
      navigate('/private')
  } catch (err) {
      if (!err?.originalStatus) {
          // isLoading: true until timeout occurs
          setErrMsg('No Server Response');
      } else if (err.originalStatus === 400) {
          setErrMsg('Missing Username or Password');
      } else if (err.originalStatus === 401) {
          setErrMsg('Unauthorized');
      } else {
          setErrMsg('Login Failed');
      }
      errRef.current.focus();
  }
}

const handleUserInput = (e) => setUser(e.target.value)

const handlePwdInput = (e) => setPwd(e.target.value)

  return (
    <Container>
      <LoginBox>
        <Title>Login</Title>
        <p style={{color:'red' , fontWeight:'bold'}} ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        
        <Form onSubmit={handleSubmit}>
                <label htmlFor="username">Username:</label>
                <Input
                    type="text"
                    id="username"
                    ref={userRef}
                    value={user}
                    onChange={handleUserInput}
                    autoComplete="off"
                    required
                />

                <label htmlFor="password">Password:</label>
                <Input
                    type="password"
                    id="password"
                    onChange={handlePwdInput}
                    value={pwd}
                    autoComplete="off"
                    required
                />
                <Button>Sign In</Button>
                <NavLink to='/'> Back to home </NavLink>
            </Form>
      </LoginBox>
    </Container>
  );
};

export default LoginPage;

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const LoginBox = styled.div`
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  color: #000;
  margin-bottom: 20px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;

  &:hover {
    background-color: #333;
  }
`;

const NavLink = styled(Link)`
  padding: 10px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s;
`;