import React from "react";

import styled from "styled-components";

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  padding: 5px;
`;

const GridItem = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  filter: brightness(0.5) contrast(1.2) grayscale(1);
  transition: filter 0.1s;

  ${GridItem}:hover & {
    filter: grayscale(0%);
  }
`;

const Text = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
`;

const items = [
  {
    text: "Item 1",
    image:
      "https://dpjn.com/wp-content/fb/images/677948902220864_1035064471958126-0.jpg",
  },
  {
    text: "Item 2",
    image:
      "https://dpjn.com/wp-content/fb/images/677948902220864_1035064471958126-0.jpg",
  },
  {
    text: "Item 3",
    image:
      "https://dpjn.com/wp-content/fb/images/677948902220864_1035064471958126-0.jpg",
  },
  {
    text: "Item 4",
    image:
      "https://dpjn.com/wp-content/fb/images/677948902220864_1035064471958126-0.jpg",
  },
];

const ProjectsSection = () => (
  <GridContainer id="projects">
    {items.map((item, index) => (
      <GridItem key={index}>
        <Image src={item.image} alt={item.text} />
        <Text>{item.text}</Text>
      </GridItem>
    ))}
  </GridContainer>
);

export default ProjectsSection;
