import React from 'react';

const ContactSection = () => {
  return (
    <div id="contact" style={{ padding: '100px 20px', backgroundColor: '#020202' , color: '#fff' }}>
      <h2>Contact Section</h2>
      <p>Get in touch with us here.</p>
    </div>
  );
};

export default ContactSection;