import React from 'react';
import styled from 'styled-components';

const Footer = () => {
  return (
    <FooterContainer>
      <SocialIcons>
        <IconLink href="#" aria-label="Facebook">🔵</IconLink>
        <IconLink href="#" aria-label="Twitter">🔷</IconLink>
        <IconLink href="#" aria-label="Instagram">🟣</IconLink>
        <IconLink href="#" aria-label="LinkedIn">🔗</IconLink>
      </SocialIcons>
      <Copyright>
        &copy; Add later with dynamic year
      </Copyright>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #0b0b0b;
  color: white;
  text-align: center;
  padding: 10px 0;
`;

const SocialIcons = styled.div`
  margin: 10px 0;
`;

const IconLink = styled.a`
  margin: 0 10px;
  color: white;
  text-decoration: none;
  font-size: 24px;
`;

const Copyright = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

export default Footer;