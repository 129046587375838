import React from "react";
import { Routes, Route } from "react-router-dom";

import LoginPage from "./features/auth/LoginPage";
import Public from "./components/Public";
import Layout from "./components/Layout";
import PrivateProjects from "./components/PrivateProjects";
import RequireAuth from "./features/auth/RequireAuth";
import UsersList from "./features/users/UsersList";

const NotFound = () => <h2>404 - Page Not Found</h2>;

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route index element={<Public />} />
          <Route path="login" element={<LoginPage />} />

          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="private" element={<PrivateProjects />} />
            <Route path="userslist" element={<UsersList />} />
          </Route>
          {/* catch-all route for 404 */}
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
