import { Link } from "react-router-dom"

const PrivateProjects = () => {
  return (
    <>
      <h1>Private Projects</h1>

      <p><Link to="/userslist">Go to the Users List</Link></p>
    </>
  );
};

export default PrivateProjects;
