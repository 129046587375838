import GlobalStyles from '../fonts/GlobalStyles';
import Navbar from '../components/NavBar';
import AboutSection from '../sections/AboutSection';
import ContactSection from '../sections/ContactSection';
import ProjectsSection from '../sections/ProjectsSection';
import Footer from './Footer';
import VideoSection from '../sections/VideoSection';


const Public = () => {
  return (
    <>
      <GlobalStyles />
      <Navbar />
      <VideoSection/>
      {/* <AboutSection /> */}
      <ProjectsSection />
      <ContactSection />
      <Footer />
    </>
  );
};
export default Public;
