// src/fonts/GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

  @font-face {
    font-family: 'Montreal-medium';
    src: url('/fonts/Montreal-medium.woff2') format('woff2'),
         url('/fonts/Montreal-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Montreal-book';
    src: url('/fonts/Montreal-book.woff2') format('woff2'),
         url('/fonts/Montreal-book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  body {
    font-family: 'Montreal-book', sans-serif;
  }
`;

export default GlobalStyles;