import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-scroll';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <NavbarContainer>
      <Logo>Yvonne Harb</Logo>
      <Hamburger onClick={toggleMenu}>☰</Hamburger>
      <Menu isOpen={isOpen}>
        <CloseButton onClick={toggleMenu}>×</CloseButton>
        <MenuItem>
          <Link to="about" smooth={true} duration={500} onClick={toggleMenu}>
            ABOUT
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="projects" smooth={true} duration={500} onClick={toggleMenu}>
            PROJECTS
          </Link>
        </MenuItem>
        <MenuItem>
          <Link to="contact" smooth={true} duration={500} onClick={toggleMenu}>
            CONTACT
          </Link>
        </MenuItem>
      </Menu>
    </NavbarContainer>
  );
};

const NavbarContainer = styled.nav`
  background-color: #0b0b0b;
  color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #fafafa;
`;

const Logo = styled.div`
  font-family: 'Playfair Display', serif;
  font-size: 1.1rem;
  font-weight: normal;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0b0b0b;
    z-index: 10;
  }
`;

const MenuItem = styled.li`
  font-family: 'Montreal-medium', sans-serif;
  cursor: pointer;
  margin: 10px 0;
  font-size: 0.7rem;
  animation: ${fadeIn} 1.5s ease-out;
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  font-size: 24px;

  @media (max-width: 768px) {
    display: block;
  }
`;

const CloseButton = styled.div`
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

export default Navbar;