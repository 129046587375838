import React from 'react';
import styled from 'styled-components';
import Video from '../images/main.mp4'

const VideoBackgroundSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  background-color: rgba(0, 0, 0, .7);
`;

const BackgroundVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

const CenteredText = styled.div`
  position: relative;
  z-index: 1;
  font-size: 2em;
  font-weight: bold;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for better text visibility */
  border-radius: 10px;
`;

const VideoSection = () => (
  <VideoBackgroundSection>
    <BackgroundVideo autoPlay loop muted>
      <source src={Video} type="video/mp4" />
      Your browser does not support the video tag.
    </BackgroundVideo>
    <CenteredText>VONVON</CenteredText>
  </VideoBackgroundSection>
);

export default VideoSection;